:root {
  --dark-navy: #15202b; 
  --navy: #0f161e; 
  --light-navy: #16202b;
  --lightest-navy: #233345;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #bc13fe; 
  --green-tint: rgba(188, 19, 254, 0.1);
}
