#projects {
  padding-left: 15%;
  padding-top: 12%;
  padding-right: 25%;
  min-height: 100vh;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 10px;
}
@media (max-width: 1080px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.projects-card {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  background-color: var(--light-navy);
  border-radius: 16px;
}

.projects-card:hover {
  transform: translateY(-7px);
  background-color: var(--lightest-navy);
}

.card-header {
  margin-top: -20px;
  display: flex;
  padding: 1.25em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
}
.folder-icon {
  color: var(--green-bright);
}

.github-icon {
  margin-top: 6px;
}
.github-icon:hover {
  color: var(--green-bright);
}

.open-icon {
  margin-left: 10px;
}

.card-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 24px;
  font-weight: bold;
}

.card-desc {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 18px;
  padding-bottom: 40px;
}

.card-tech {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  #projects {
    height: unset;
    padding-top: 30%;
    padding-left: unset;
    padding-right: unset;
  }
}
